'use client';
import { useContext } from 'react';
import Link from 'next/link';
import RouterProgressionContext from '@/contexts/RouterProgressionContext';

/**
 * The original Link component from Next.js no longer has router events.
 * This component is a wrapper around the Link component that adds the router events.
 * Helpful for adding a loading bar to the top of the page.
 */
// export const Anchor = forwardRef<HTMLAnchorElement, ComponentPropsWithoutRef<typeof Link>>((props, ref) => {
//   const startChange = useContext(RouterProgressionContext);
//   const href: string = props.href;
//   const { ...otherProps } = props;
//   if (href.startsWith('/'))
//     return (
//       <Link
//         ref={ref}
//         href={href}
//         onClick={() => {
//           const { pathname, search, hash } = window.location;
//           if (href !== pathname + search + hash) startChange();
//         }}
//         {...otherProps}
//       ></Link>
//     );
//   return <a href={href} {...otherProps}></a>;
// });

export default function Anchor(props) {
  const startChange = useContext(RouterProgressionContext);

  if (props.href.startsWith('/'))
    return (
      <Link
        href={props.href}
        passHref
        onClick={() => {
          const { pathname, search, hash } = window.location;
          if (props.href !== pathname + search + hash) startChange();
          if (props.onClick) props.onClick();
        }}
        {...props}
        data-oid='71qawwn'
      />
    );

  const { prefetch, ...otherProps } = props;
  return <a href={props.href} {...otherProps} data-oid='-oyl..q'></a>;
}
