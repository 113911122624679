'use client';

import * as React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import Anchor from '@/components/Anchor/Anchor';
import { useNotification } from '@/hooks/useNotification';
import { DashedDivider4 } from '@/components/ui/divider-dashed';
import { SocialButton } from '@/components/ui/social-button';
import { Label, Asterisk } from '@/components/ui/label';
import * as Input from '@/components/ui/input';
import { FooterCommon } from '../footers';
import { ButtonPrimary } from '@/components/ui/button';
import {
  useSignInWithMagicLink,
  useSignInWithPassword,
  useSignInWithProvider,
} from '@/utils/react-query-hooks';
import { APP_DOMAIN } from '@/constants';
import { LoadingSpinner } from '@/components/presentational/tailwind/LoadingSpinner';

import IconGoogle from '@public/icons/google.svg';
import IconEmail from '@public/icons/email-1.svg';
import IconLock from '@public/icons/lock.svg';
import IconEyeOpen from '@public/icons/eye-open.svg';

export default function LoginPage() {
  const [showPassword, setShowPassword] = React.useState(false);
  const [isSuccessful, setIsSuccessful] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState<string | null>(null);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [showMagicLink, setShowMagicLink] = React.useState<boolean>(false);
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');

  const { notification } = useNotification();

  function redirectToDashboard() {
    setIsSuccessful(true);
    window.location.href = `${process.env.NODE_ENV == 'production' ? 'https://' : 'http://'}${APP_DOMAIN}`;
  }

  // Google Login
  const providerMutation = useSignInWithProvider();
  // Email and Password Login
  const passwordMutation = useSignInWithPassword({
    onSuccess: redirectToDashboard,
    onError: (error: any) => {
      setErrorMessage(error.message);
      notification({
        title: 'Invalid login credentials',
        status: 'error',
      });
    },
  });
  // Magic Link Login
  const magicLinkMutation = useSignInWithMagicLink({
    onSuccess: () => {
      setSuccessMessage('A magic link has been sent to your email!');
    },
    onError: (error: any) => {
      setErrorMessage(error.message);
    },
  });

  const handleLogin = React.useCallback(
    (e?: React.FormEvent) => {
      e?.preventDefault();
      console.log('Login form submitted'); // Debug log
      if (showMagicLink) {
        magicLinkMutation.mutate({ email });
      } else {
        passwordMutation.mutate({ email, password });
      }
    },
    [showMagicLink, email, password, magicLinkMutation, passwordMutation],
  );

  const handleGoogleLogin = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault(); // Prevent any default action
      e.stopPropagation(); // Stop event from bubbling up
      console.log('Google login clicked'); // Debug log
      providerMutation.mutate({ provider: 'google' });
    },
    [providerMutation],
  );

  if (isSuccessful) {
    return (
      <div className='items-center mx-auto mt-10 text-center'>
        <LoadingSpinner className='w-8 h-8 !mx-auto' />
        <p className='mt-3 font-medium text-purple-500 text-md'>
          Logging you in ...
        </p>
      </div>
    );
  }

  return (
    <>
      <div className='flex flex-col flex-1'>
        <div className='relative flex flex-1 flex-col justify-center py-12 sm:pb-16 sm:pt-[124px] [@media(min-height:900px)]:items-center'>
          <form onSubmit={handleLogin} className='mx-auto w-full max-w-[324px] shrink-0 flex-col gap-8 flex justify-center'>
            <Anchor href='/' className='group'>
              <Image
                src='/images/logo.svg'
                alt='CreditCaptain Logo'
                width={32}
                height={32}
                priority
                className='transform-gpu transition-all duration-500 ease-in-out group-hover:translate-x-1 group-hover:rotate-[360deg] group-hover:scale-[87%]'
              />
            </Anchor>
            <div>
              <div className='text-display-m text-neutral-800'>
                Login to your account
              </div>
              <div className='mt-2 text-body-s text-neutral-600'>
                Welcome back! Log in to your CreditCaptain account to continue
                improving your credit scores.
              </div>
            </div>

            {errorMessage && (
              <div className='relative px-4 py-3 text-white bg-red-500 rounded-xl' role='alert'>
                <span className='block sm:inline'>
                  {errorMessage}
                </span>
                <span className='absolute top-0 bottom-0 right-0 px-4 py-3'>
                  <svg
                    className='w-6 h-6 text-white fill-current'
                    role='button'
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 20 20'
                    onClick={() => setErrorMessage(null)}
                  >
                    <title>Close</title>
                    <path d='M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z'></path>
                  </svg>
                </span>
              </div>
            )}

            <div className='flex flex-col gap-6'>
              <SocialButton onClick={handleGoogleLogin} type='button'>
                <IconGoogle className='size-5' />
                Sign in with Google
              </SocialButton>

              <div className='flex items-center gap-2.5 text-caption-s text-neutral-400'>
                <DashedDivider4 />
                OR
                <DashedDivider4 />
              </div>

              <div className='flex flex-col gap-3'>
                <div className='flex flex-col gap-1'>
                  <Label htmlFor='email'>
                    Email Address
                  </Label>

                  <Input.Root>
                    <Input.Wrapper>
                      <Input.Icon icon={IconEmail} />
                      <Input.Input
                        id='email'
                        type='email'
                        placeholder='hello@creditcaptain.com'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Input.Wrapper>
                  </Input.Root>
                </div>

                {!showMagicLink && (
                  <div className='flex flex-col gap-1'>
                    <div className='flex items-center justify-between'>
                      <Label htmlFor='password'>
                        Password
                      </Label>
                      <Link href='/forgot-password' className='text-body-s text-neutral-500'>
                        Forgot?
                      </Link>
                    </div>

                    <Input.Root>
                      <Input.Wrapper>
                        <Input.Icon icon={IconLock} />
                        <Input.Input
                          id='password'
                          type={showPassword ? 'text' : 'password'}
                          placeholder='• • • • • • • • • •'
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />

                        <button type='button' onClick={() => setShowPassword((s) => !s)}>
                          {showPassword ? (
                            <IconEyeOpen className='size-[18px] text-neutral-400' />
                          ) : (
                            <IconEyeOpen className='size-[18px] text-neutral-400' />
                          )}
                        </button>
                      </Input.Wrapper>
                    </Input.Root>
                  </div>
                )}
              </div>

              <ButtonPrimary
                size='large'
                className='w-full'
                type='submit'
                disabled={passwordMutation.isLoading || magicLinkMutation.isLoading}
              >
                {passwordMutation.isLoading || magicLinkMutation.isLoading
                  ? 'Loading...'
                  : 'Login'}
              </ButtonPrimary>
            </div>

            <div className='text-body-s text-neutral-600 text-center'>
              <p className='w-full text-sm font-normal text-center text-neutral-600 mb-5'>
                Or log in{' '}
                {!showMagicLink ? (
                  <button type='button' className='text-neutral-700 text-action relative after:absolute after:bg-neutral-700 after:bottom-0 after:left-0 after:h-[1px] after:w-0 hover:after:w-full after:transition-all after:duration-300 focus:outline-none' onClick={() => setShowMagicLink(true)}>
                    using a magic link{' '}
                    <span className='font-thin'>➜</span>
                  </button>
                ) : (
                  <button type='button' className='text-neutral-700 text-action relative after:absolute after:bg-neutral-700 after:bottom-0 after:left-0 after:h-[1px] after:w-0 hover:after:w-full after:transition-all after:duration-300 focus:outline-none' onClick={() => setShowMagicLink(false)}>
                    using email and password{' '}
                    <span className='font-thin'>➜</span>
                  </button>
                )}
              </p>

              Don't have an account?{' '}

              <Link href='/sign-up' className='text-action text-neutral-700 relative after:absolute after:bg-neutral-700 after:bottom-0 after:left-0 after:h-[1px] after:w-0 hover:after:w-full after:transition-all after:duration-300'>
                Get started for free
              </Link>

            </div>

          </form>
        </div>
      </div>

      <FooterCommon />
    </>
  );
}